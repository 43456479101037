/* global angular, config, NaN, Notification */

'use strict';

const app = angular.module('managerApp', [
    'managerApp.auth', 'managerApp.partner', 'managerApp.devicesManager',
    'ngCookies', 'ngResource', 'ngSanitize', 'ngRoute',
    'btford.socket-io', 'validation.match', 'ngMaterial', 'ngMaterialSidemenu',
    'md.data.table', 'ngMessages', 'ui.bootstrap', 'ngclipboard', 'ngIdle',
    'ngPageTitle', 'cfp.hotkeys','ja.qr'
]);
app.config(function ($routeProvider, $locationProvider) {
    $routeProvider.otherwise({
        redirectTo: '/'
    });
    $locationProvider.html5Mode(true);
}).config(function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|sms|tel|web\+aircall):/);
}).config(function ($mdThemingProvider) {
    $mdThemingProvider.definePalette('amazingPaletteName', {
        '50': 'ffebee',
        '100': 'ffcdd2',
        '200': 'ef9a9a',
        '300': 'e57373',
        '400': 'ef5350',
        '500': 'E30614',
        '600': 'e53935',
        '700': 'ff00ff',
        '800': 'c62828',
        '900': 'b71c1c',
        'A100': 'ff8a80',
        'A200': 'ff5252',
        'A400': 'ff1744',
        'A700': 'd50000',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'],
        'contrastLightColors': undefined
    });

    $mdThemingProvider.theme('default').primaryPalette('amazingPaletteName');
    $mdThemingProvider.theme('success-toast');
    $mdThemingProvider.theme('error-toast');

}).config(function ($mdDateLocaleProvider) {

    $mdDateLocaleProvider.months = 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_');
    $mdDateLocaleProvider.shortMonths = 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_');
    $mdDateLocaleProvider.days = 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_');
    $mdDateLocaleProvider.shortDays = 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_');

    // Can change week display to start on Monday.
    $mdDateLocaleProvider.firstDayOfWeek = 1;

    // Example uses moment.js to parse and format dates.
    $mdDateLocaleProvider.parseDate = function (dateString) {
        var m = moment(dateString, config.date.defaultViewFormat);
        return m.isValid() ? m.toDate() : new Date(NaN);
    };

    $mdDateLocaleProvider.formatDate = function (date) {
        var m = moment(date);
        return m.isValid() ? m.format(config.date.defaultViewFormat) : '';
    };

    // Allow only a day and month to be specified.
    // This is required if using the 'M/D' format with moment.js.
    $mdDateLocaleProvider.isDateComplete = function (dateString) {
        dateString = dateString.trim();
        // Look for two chunks of content (either numbers or text) separated by delimiters.
        var re = /^(([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-]))([a-zA-Z]{3,}|[0-9]{1,4})/;
        return re.test(dateString);
    };

    $mdDateLocaleProvider.monthHeaderFormatter = function (date) {
        return $mdDateLocaleProvider.shortMonths[date.getMonth()] + ' ' + date.getFullYear();
    };

    // In addition to date display, date components also need localized messages
    // for aria-labels for screen-reader users.
    $mdDateLocaleProvider.weekNumberFormatter = function (weekNumber) {
        return 'Semaine ' + weekNumber;
    };

    $mdDateLocaleProvider.msgCalendar = 'Calendrier';
    $mdDateLocaleProvider.msgOpenCalendar = 'Ouvrir le calendrier';

    // You can also set when your calendar begins and ends.
    $mdDateLocaleProvider.firstRenderableDate = new Date(1900, 0, 1);
    $mdDateLocaleProvider.lastRenderableDate = new Date(3000, 0, 1);
    //$mdDateLocaleProvider.maxDate = new Date();

}).config(function (IdleProvider) {

    IdleProvider.idle(config.idle.idleDuration);
    IdleProvider.timeout(config.idle.timeoutDuration);

}).config(function ($pageTitleProvider) {

    $pageTitleProvider.setDefault('Back-office - Réflexe Carte Grise');

}).config(function (hotkeysProvider) {

    hotkeysProvider.includeCheatSheet = false;
    hotkeysProvider.useNgRoute = false;

})/*.config(function ($httpProvider) {
 
 $httpProvider.interceptors.push('preventTemplateCache');
 
 })*/.filter('trusted', function ($sce) {
    return function (url) {
        return $sce.trustAsResourceUrl(url);
    };
}).run(function ($rootScope, $location, $cookies, $mdSidenav, Idle, hotkeys, Auth) {

    console.log('app running !', 'error');

//------------------------------------------------------------------------------    

    $rootScope.config = config;

    $cookies.put(config.fromHeader.name, config.fromHeader.value);

    startIdleWatch();

// --- hotkeys -----------------------------------------------------------------

    hotkeys.add({
        combo: 'm',
        callback: function () {
            $mdSidenav('left-sidebar').toggle();
        }
    });

    hotkeys.add({
        combo: '1',
        callback: function () {
            $location.path('/procedures/certificat-immatriculation');
        }
    });

    hotkeys.add({
        combo: '2',
        callback: function () {
            $location.path('/procedures/changement-adresse');
        }
    });

// --- events ------------------------------------------------------------------

    var eventSource = startEventsListening();
    function startEventsListening() {

        if (config.isPartnerSession) {
            return;
        }

        let authToken = $cookies.get(config.auth.token);
        if (authToken) {
            let url = config.apiURL + 'events/bo';
            let source = new EventSourcePolyfill(url, {
                headers: {
                    'authorization': 'Bearer ' + authToken
                }
            });
            source.addEventListener('error', (e) => {
                if (e && e.error && e.error.message === 'network error') {
                    $rootScope.$broadcast('connection-lost');
                }
            });
            source.addEventListener('open', (e) => {
                $rootScope.$broadcast('connected');
            });
            source.addEventListener('bo-event', (e) => {
                if (e.data) {
                    let data = e.data;
                    try {
                        data = JSON.parse(data);
                        if (data.message) {
                            showMessage(data);
                            return;
                        }
                    } catch (ex) {
                    }
                }
            });
            return source;
        }
        return null;
    }

    function showNotif(message = '', title = 'RCG - notification') {
        let options = {
            body: message,
            icon: '/assets/img/notification_rcg_logo.png',
            badge: '/assets/img/notification_icon.png'
        };
        const notif = new Notification(title, options);
        notif.onclick = () => {
        };
    }

    function showMessage(message) {
        if (!message) {
            return;
        }
        if (!window.Notification) {
            alert('Ce navigateur web ne supporte pas les notifications.' +
                    ' Il faut le mettre à niveau ou utiliser un plus récent.');
        } else {
            if (Notification.permission === 'granted') {
                showNotif(message.message, message.title);
                return;
            } else {
                Notification.requestPermission().then((p) => {
                    if (p === 'granted') {
                        console.log('Les notifications sur le back-office sont AUTORISÉES');
                        showNotif(message.message, message.title);
                    } else {
                        console.error('Les notifications sur le back-office sont BLOQUÉES');
                        if (localStorage.getItem('notifNotGrantedAlert') === null) {
                            //alert('Vous avez bloqué les notifcations.');
                            localStorage.setItem('notifNotGrantedAlert', true);
                        }
                    }
                }).catch((err) => {
                    console.error('Notification.requestPermission error :');
                    console.error(err);
                });
            }
        }//
    }

// --- events ------------------------------------------------------------------

    $rootScope.loggedIn = () => {
        eventSource = startEventsListening();
        startIdleWatch();
    };

    $rootScope.loggedOut = () => {
        if (eventSource) {
            eventSource.close();
        }
        Idle.unwatch();
    };

    $rootScope.gotoTab = ($scope = null, tabIndex = - 1, scrollTo = null) => {
        if ($scope !== null) {
            $scope.currentTab = tabIndex;
            if (tabIndex === 2) {
                $scope.docsCurrentTab = 0;
            }
            if (scrollTo !== null) {
                const nbSteps = 10;
                const step = scrollTo / nbSteps, init = 0, timeoutStep = 50;
                const target = document.getElementById('content');
                for (let i = 0; i < nbSteps; i++) {
                    setTimeout(() => {
                        target.scrollTo(0, step + i * step);
                    }, init + i * timeoutStep);
                }
            }
        }//
    };

    $rootScope.isSet = (obj) => {
        return obj !== null && (typeof obj !== 'undefined');
    };

    function startIdleWatch() {
        let user = Auth.getCurrentUser();
        if (user !== null && user.isAgent === true) {
            console.log('start idle watch');
            Idle.watch();
        }
    }

});



function getElementById(id) {
    return document.getElementById(id);
}
